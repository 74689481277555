angular.module("app")
    .factory('_mapService', function ($rootScope, _v, _calendar, _logicService) {

        //google map object
        var gmap = null;

        var defaultZoom = 3;
        var defaultCenter = new google.maps.LatLng(29, -26);

        //fields shapes cache. field.id->Polygon or Circle
        var fieldShapes = {};


        var CONSTANT = {
            EPS: 1e-12,
            POLYGON: 1,
            CIRCLE: 2,

            BOUNDS: 'bounds',   //bezier arc point type
            CONTROL: 'control', //bezier arc point type
            CENTER: 'center',   //bezier arc point type

            COLOR_NEW_FIELD_STROKE: "#FFFFFF",
            COLOR_ACTIVE_FIELD_STROKE: "#ffff00",

            COLOR_FIELD_STROKE: "#FFFFFF",
            COLOR_SELECTED_FIELD_STROKE: "#01FF51",
            FIELD_STROKE_WEIGHT: 2,
            OPACITY_FIELD: 0.5,
            OPACITY_FIELD_HOVER: 0.7,
            COLOR_FIELD: "#8AE17A",
            GRID_CELL_COLOR: "#023A48",
            GRID_PALE_CELL_COLOR: "#7A7D7A",
            ZINDEX: {
                FIELD: 10,
                GRID: 20,
                GRID_GROUP: 21,
                GRID_BORDER: 28,
                POINTS: 30,
                PATHLINE: 100040,
                RESIZE_MARKER: 100050,
                PATHLINE_MARKER: 100060,
                START_MARKER: 100070,
                FIRST_CELL: 100080,
                BASELINE: 100090,
                BASELINE_MARKER: 100100,
                MAX: 100500
            }
        };

        CONSTANT.fieldPolyOptions = {strokeWeight: CONSTANT.FIELD_STROKE_WEIGHT, strokeColor: CONSTANT.COLOR_FIELD_STROKE, fillOpacity: CONSTANT.OPACITY_FIELD, fillColor: CONSTANT.COLOR_FIELD, zIndex: CONSTANT.ZINDEX.FIELD};

        // Assign variables inside function
        // to make possible to edit variables outside of the scope
        // Usage: _mapService.vars.fieldShapes[0] = {}
        function vars() {}

        vars.gmap = gmap;
        vars.fieldShapes = fieldShapes;


        var SELECTED_MARKER = {};

        var UNSELECTED_MARKER = {};

        function mid(a, b) {
            return (a+b)/2;
        }

        function setupCenter(circleObject) {
            circleObject.updateCenter = function() {
                var s = Math.min.apply(Math, _.map(circleObject.markers, m => m.getPosition().lat() ));
                var w = Math.min.apply(Math, _.map(circleObject.markers, m => m.getPosition().lng() ));
                var n = Math.max.apply(Math, _.map(circleObject.markers, m => m.getPosition().lat() ));
                var e = Math.max.apply(Math, _.map(circleObject.markers, m => m.getPosition().lng() ));

                this.center.setPosition(new google.maps.LatLng(mid(s,n), mid(w,e)));
            };

            circleObject.updateCenter();

            google.maps.event.addListener(circleObject.center, "mousedown", (event) => {
                circleObject.oldCenter = event.latLng;
                circleObject.markers.forEach(marker => marker.oldPosition = marker.getPosition());
            })
        }

        function getLatLngBounds(coordinates) {
            var s = Math.min.apply(Math, _.map(coordinates, o => o[0]));
            var w = Math.min.apply(Math, _.map(coordinates, o => o[1]));
            var n = Math.max.apply(Math, _.map(coordinates, o => o[0]));
            var e = Math.max.apply(Math, _.map(coordinates, o => o[1]));

            var sw = new google.maps.LatLng(s, w);
            var ne = new google.maps.LatLng(n, e);

            return new google.maps.LatLngBounds(sw, ne);
        }

        //gCircle creation from API data
        function geojsonToGCircle(field) {  //gCircle
            var circleObject = {markers: []};
            var path = geojsonToPolygon(field.controlPoints).getPath();

            for (var i = 0, len = path.getLength(); i < len; i += 3) {
                var c1 = path.getAt(i);
                var m = path.getAt(i+1);
                var c2 = path.getAt(i+2);
                var marker = createBezierPoint(m.lat(), m.lng(), circleObject, c1.lat()- m.lat(), c1.lng()- m.lng(), c2.lat()- m.lat(), c2.lng()- m.lng());
                marker.setVisible(false);
                marker.c1.setVisible(false);
                marker.c2.setVisible(false);
                circleObject.markers.push(marker);
            }

            circleObject.center = createMarker(circleObject.markers[0].getPosition().lat(), circleObject.markers[0].getPosition().lng(), circleObject, CONSTANT.CENTER);
            circleObject.center.setVisible(false);
            setupCenter(circleObject);
            drawBezier(circleObject);
            addGoogleFunctionsToGCircle(circleObject);

            circleObject.markers.forEach(marker => {
                marker.midPoint.setVisible(false);
                marker.p1.setVisible(false);
                marker.p2.setVisible(false);
            });

            return circleObject;
        }

        function geojsonToPolygon(coordinates) {
            var polygon = new google.maps.Polygon();
            var path = [];

            _.each(coordinates.coordinates[0], function(p, i) {
                if (i < _.size(coordinates.coordinates[0]) - 1) {
                    path.push(new google.maps.LatLng(p[1], p[0]));
                }
            });

            polygon.setPath(path);
            return polygon;
        }

        /**
         * Arc
         */

        function splitArc(event, midPoint) {
            //http://jeremykun.files.wordpress.com/2013/05/subdivision.png?w=1800
            var circleObject = midPoint.m1.circleObject;

            var ind = -1;
            for (var i = 0; i < circleObject.markers.length; i++) {
                var marker = circleObject.markers[i];
                if (marker.id == midPoint.m1.id) {
                    ind = i;
                }
            }

            var m1lat = mid(midPoint.m1.c2.getPosition().lat(), midPoint.m2.c1.getPosition().lat());
            var m1lng = mid(midPoint.m1.c2.getPosition().lng(), midPoint.m2.c1.getPosition().lng());

            midPoint.m1.dlat2 = midPoint.m1.dlat2/2;
            midPoint.m1.dlng2 = midPoint.m1.dlng2/2;
            midPoint.m1.c2.setPosition(new google.maps.LatLng(midPoint.m1.getPosition().lat() + midPoint.m1.dlat2,
                midPoint.m1.getPosition().lng() + midPoint.m1.dlng2));

            midPoint.m2.dlat1 = midPoint.m2.dlat1/2;
            midPoint.m2.dlng1 = midPoint.m2.dlng1/2;
            midPoint.m2.c1.setPosition(new google.maps.LatLng(midPoint.m2.getPosition().lat() + midPoint.m2.dlat1,
                midPoint.m2.getPosition().lng() + midPoint.m2.dlng1));

            var q0lat = mid(m1lat, midPoint.m1.c2.getPosition().lat());
            var q0lng = mid(m1lng, midPoint.m1.c2.getPosition().lng());

            var q1lat = mid(m1lat, midPoint.m2.c1.getPosition().lat());
            var q1lng = mid(m1lng, midPoint.m2.c1.getPosition().lng());

            var newPoint = createBezierPoint(midPoint.getPosition().lat(), midPoint.getPosition().lng(), circleObject,
                q0lat - midPoint.getPosition().lat(), q0lng - midPoint.getPosition().lng(),
                q1lat - midPoint.getPosition().lat(), q1lng - midPoint.getPosition().lng());

            circleObject.markers.splice(ind + 1, 0, newPoint);

            midPoint.m1.midPoint = null;    //new midpoints will be created in drawBezier call
            midPoint.setMap(null);          //delete old mid point
            drawBezier(circleObject);
        }

        function splineArc(path, m1, m2, m3, m4) {
            var x1 = m1.getPosition().lng();
            var y1 = m1.getPosition().lat();
            var x2 = m2.getPosition().lng();
            var y2 = m2.getPosition().lat();
            var x3 = m3.getPosition().lng();
            var y3 = m3.getPosition().lat();
            var x4 = m4.getPosition().lng();
            var y4 = m4.getPosition().lat();
            var MAX = 50;
            for (var i = 0; i < MAX; i+=1) {
                var t = i/MAX;
                var x = (1-t)*(1-t)*(1-t)*x1 + 3*t*(1-t)*(1-t)*x2 +3*t*t*(1-t)*x3 + t*t*t*x4;
                var y = (1-t)*(1-t)*(1-t)*y1 + 3*t*(1-t)*(1-t)*y2 +3*t*t*(1-t)*y3 + t*t*t*y4;
                path.push(new google.maps.LatLng(y, x));

                if (i == Math.floor(MAX/2)) { //midpoint
                    var midPoint = m1.midPoint;
                    if (!midPoint) {
                        m1.midPoint = new google.maps.Marker({position: new google.maps.LatLng(y, x), map: vars.gmap, options: {draggable: false},
                            crossOnDrag: false, icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                scale: 3,
                                strokeColor: "#cccccc",
                                fillColor: "#cccccc",
                                fillOpacity: 1
                            }
                        });
                        m1.midPoint.m1 = m1;
                        google.maps.event.addListener(m1.midPoint, "click", event => splitArc(event, m1.midPoint));
                    } else {
                        m1.midPoint.setPosition(new google.maps.LatLng(y, x));
                    }
                    m1.midPoint.m2 = m4; //must be here to fix mid point after next arc delete
                }
            }

            var p2 = m1.p2;
            if (!p2) {
                p2 = new google.maps.Polyline({map: vars.gmap, options: {strokeColor: "#FFFFFF", strokeWeight: 1}});
                m1.p2 = p2;
            }
            p2.setPath([m1.getPosition(), m2.getPosition()]);

            var p1 = m4.p1;
            if (!p1) {
                p1 = new google.maps.Polyline({map: vars.gmap, options: {strokeColor: "#FFFFFF", strokeWeight: 1}});
                m4.p1 = p1;
            }
            p1.setPath([m4.getPosition(), m3.getPosition()]);
        }

        /**
         * Bezier
         */

        function drawBezier(circleObject, marker) {
            var polygon = circleObject.polygon;
            if (!polygon) {
                polygon = new google.maps.Polygon();
                polygon.setOptions(CONSTANT.fieldPolyOptions);
                polygon.setMap(vars.gmap);
                circleObject.polygon = polygon;
            }

            var path = [];
            var a = circleObject.markers;
            var l = circleObject.markers.length;

            for (var i = 0; i < l; i++) {
                if (i < l-1) {
                    splineArc(path, a[i], a[i].c2, a[i+1].c1, a[i+1]);
                }
                if (i == l-1) {
                    splineArc(path, a[i], a[i].c2, a[0].c1, a[0]);
                }
            }

            polygon.setPath(path);
        }

        function createBezierPoint(lat, lng, circleObject, dlat1, dlng1, dlat2, dlng2) {
            var m = createMarker(lat, lng, circleObject, CONSTANT.BOUNDS);
            google.maps.event.addListener(m, "rightclick", () => deleteMarker(circleObject, m));

            m.id = _.uniqueId();
            m.c1 = createMarker(lat + dlat1, lng + dlng1, circleObject, CONSTANT.CONTROL);
            m.c1.m = m;
            m.c1.num = 1;
            m.dlat1 = dlat1;
            m.dlng1 = dlng1;
            m.c2 = createMarker(lat + dlat2, lng + dlng2, circleObject, CONSTANT.CONTROL);
            m.dlat2 = dlat2;
            m.dlng2 = dlng2;
            m.c2.m = m;
            m.c2.num = 2;
            return m;
        }

        /**
         * Marker
         */

        function createMarker(lat, lng, circleObject, type) {
            var marker = new google.maps.Marker({position: new google.maps.LatLng(lat, lng), map: vars.gmap, options: {draggable: true},
                cursor: 'move',
                circleObject: circleObject,
                crossOnDrag: false,
                icon: UNSELECTED_MARKER
            });
            marker.markerType = type;
            google.maps.event.addListener(marker, "drag", function (event) {moveMarker(this.circleObject, event, marker);});
            google.maps.event.addListener(marker, "mouseup", function (event) {mouseUpMarker(this.circleObject, event, marker);});
            return marker;
        }

        function deleteMarker(circleObject, marker) {
            if (marker.markerType == CONSTANT.BOUNDS) {
                if (circleObject.markers.length > 2) {
                    var ind = -1;
                    for (var i = 0; i < circleObject.markers.length; i++) {
                        var m = circleObject.markers[i];
                        if (m.id == marker.id) {
                            ind = i;
                        }
                    }

                    marker.midPoint.setMap(null);
                    marker.c1.setMap(null);
                    marker.p1.setMap(null);
                    marker.c2.setMap(null);
                    marker.p2.setMap(null);
                    marker.setMap(null);

                    var prevMarker = circleObject.markers[circleObject.markers.length - 1];
                    if (ind > 0) {
                        prevMarker = circleObject.markers[ind - 1];
                    }
                    prevMarker.dlat2 *=2;
                    prevMarker.dlng2 *=2;
                    prevMarker.c2.setPosition(new google.maps.LatLng(prevMarker.getPosition().lat() + prevMarker.dlat2, prevMarker.getPosition().lng() + prevMarker.dlng2));

                    var nextMarker = circleObject.markers[0];
                    if (ind < circleObject.markers.length - 1) {
                        nextMarker = circleObject.markers[ind + 1];
                    }
                    nextMarker.dlat1 *=2;
                    nextMarker.dlng1 *=2;
                    nextMarker.c1.setPosition(new google.maps.LatLng(nextMarker.getPosition().lat() + nextMarker.dlat1, nextMarker.getPosition().lng() + nextMarker.dlng1));

                    circleObject.markers.splice(ind, 1);
                    circleObject.updateCenter();
                    drawBezier(circleObject);
                }
            }
        }

        function moveMarker(circleObject, event, marker) {
            if (marker.markerType == CONSTANT.BOUNDS) {
                //move control points
                marker.c1.setPosition(new google.maps.LatLng(marker.getPosition().lat() + marker.dlat1, marker.getPosition().lng() + marker.dlng1));
                marker.c2.setPosition(new google.maps.LatLng(marker.getPosition().lat() + marker.dlat2, marker.getPosition().lng() + marker.dlng2));

                circleObject.updateCenter();
            }
            if (marker.markerType == CONSTANT.CONTROL) {
                //update point dx and dy
                if (marker.num == 1) {
                    marker.m.dlat1 = marker.getPosition().lat() - marker.m.getPosition().lat();
                    marker.m.dlng1 = marker.getPosition().lng() - marker.m.getPosition().lng();
                } else {
                    marker.m.dlat2 = marker.getPosition().lat() - marker.m.getPosition().lat();
                    marker.m.dlng2 = marker.getPosition().lng() - marker.m.getPosition().lng();
                }
            }
            if (marker.markerType == CONSTANT.CENTER) {
                //move whole shape
                var dlat = event.latLng.lat() - marker.circleObject.oldCenter.lat();
                var dlng = event.latLng.lng() - marker.circleObject.oldCenter.lng();

                circleObject.markers.forEach(marker => {
                    marker.setPosition(new google.maps.LatLng(marker.oldPosition.lat() + dlat, marker.oldPosition.lng() + dlng));
                    marker.c1.setPosition(new google.maps.LatLng(marker.getPosition().lat() + marker.dlat1, marker.getPosition().lng() + marker.dlng1));
                    marker.c2.setPosition(new google.maps.LatLng(marker.getPosition().lat() + marker.dlat2, marker.getPosition().lng() + marker.dlng2));
                });
            }

            drawBezier(circleObject);
        }

        function mouseUpMarker(circleObject, event, marker) {
            circleObject.updateArea();
        }

        /**
         * GCircle
         */

        function circleToGCircle(circle) {
            var circleObject = {markers: []};
            var lat1 = circle.getBounds().getNorthEast().lat();
            var lng1 = circle.getBounds().getNorthEast().lng();
            var lat2 = circle.getBounds().getSouthWest().lat();
            var lng2 = circle.getBounds().getSouthWest().lng();

            var k = 0.5522847498;
            var e = k * Math.abs(lat1 - lat2) / 2;

            var dLng = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(circle.getCenter().lat(), circle.getCenter().lng()- 0.1), new google.maps.LatLng(circle.getCenter().lat(), circle.getCenter().lng() + 0.1));
            var dLat = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(circle.getCenter().lat() - 0.1, circle.getCenter().lng()), new google.maps.LatLng(circle.getCenter().lat() + 0.1, circle.getCenter().lng()));

            circleObject.markers.push(createBezierPoint(lat1, (lng1 + lng2) / 2, circleObject, 0, -e * dLat / dLng, 0, e * dLat / dLng));
            circleObject.markers.push(createBezierPoint((lat1 + lat2) / 2, lng1, circleObject, e, 0, -e, 0));
            circleObject.markers.push(createBezierPoint(lat2, (lng1 + lng2) / 2, circleObject, 0, e * dLat / dLng, 0, -e * dLat / dLng));
            circleObject.markers.push(createBezierPoint((lat1 + lat2) / 2, lng2, circleObject, -e, 0, e, 0));

            circleObject.center = createMarker(mid(lat1, lat2), mid(lng1, lng2), circleObject, CONSTANT.CENTER);
            setupCenter(circleObject);
            drawBezier(circleObject);
            addGoogleFunctionsToGCircle(circleObject);

            return circleObject;
        }

        function addGoogleFunctionsToGCircle(circleObject) {
            circleObject.setEditable = function (editable) {
                circleObject.center.setVisible(editable);
                circleObject.markers.forEach(marker => {
                    marker.setVisible(editable);
                    marker.c1.setVisible(editable);
                    marker.c2.setVisible(editable);
                    marker.midPoint.setVisible(editable);
                    marker.p1.setVisible(editable);
                    marker.p2.setVisible(editable);
                });
            };

            circleObject.setMap = function (map) {
                circleObject.center.setMap(map);
                circleObject.polygon.setMap(map);
                circleObject.markers.forEach(marker => {
                    marker.setMap(map);
                    marker.c1.setMap(map);
                    marker.c2.setMap(map);
                    marker.midPoint.setMap(map);
                    marker.p1.setMap(map);
                    marker.p2.setMap(map);
                });
            };

            circleObject.setVisible = function (visible) {
                circleObject.polygon.setVisible(visible);
            };
        }

        /**
         * Adds crops information to field
         *
         * @param {Number} fieldID              ID of a field to decorate
         * @param {Array|undefined} _crops      crops of a field.
         */
        function decorateField(fieldID, _crops) {
            if (_.has(vars.fieldShapes, fieldID)) {
                var shape = vars.fieldShapes[fieldID];
                var hasWarning = false;
                var firstWarningDate = null;
                var firstWarningDisease = null;

                shape.crops = [];
                _crops.forEach(crop => {
                        shape.crops.push({
                            'from': moment.utc(crop.seedingDate),
                            'to': _logicService.cropTo(crop),
                            'color': crop.variety.crop.color
                        });
                        if (!_.isUndefined(crop.diseaseMarks) && _.size(crop.diseaseMarks) > 0) {
                            hasWarning = true;
                            _.each(crop.diseaseMarks, function (dm) {
                                if (firstWarningDate == null || moment.utc(dm.date).isBefore(firstWarningDate)) {
                                    firstWarningDate = moment.utc(dm.date);
                                    firstWarningDisease = dm.diseaseId;
                                }
                            });
                        }
                    });
                shape.crops.sort(function (a, b) {
                    return (a.from).diff(b.from);
                });
                if (hasWarning) {
                    vars.fieldShapes[fieldID].overlay.showWarning(firstWarningDate, firstWarningDisease);
                }
            }
        }

        function colorFields() {
            // Get calendar boundaries
            var now = moment.utc();

            _calendar.getDisplayedDateBoundariesPromise().then(function(calendarBoundaries) {
                var defaultColor = '8AE17A'; // default color

                if (calendarBoundaries == null) {
                    _.values(vars.fieldShapes).forEach(shape => {
                        shape.polygon.setOptions({fillColor: '#' + defaultColor});
                    });
                    return;
                }

                if (!now.isBetween(calendarBoundaries.from, calendarBoundaries.to, null, '[]')) {
                    now = calendarBoundaries.from;
                }

                _.values(vars.fieldShapes).forEach(shape => {
                    var chosenColor = defaultColor;

                    var cropForToday = _.find(shape.crops, function(c){
                        return now.isBetween(c.from, c.to, null, '[)');
                    });

                    if (!cropForToday) { //find first future crop
                        cropForToday = _.find(shape.crops, function(c){
                            return c.from.isSameOrAfter(now);
                        });
                    }

                    if (cropForToday) {
                        chosenColor = cropForToday.color;
                    }

                    shape.polygon.setOptions({fillColor: '#' + chosenColor});
                });
            });
        }
        
        function colorActiveField(fieldId) {
            _.values(vars.fieldShapes).forEach(shape => {
                shape.polygon.setOptions({strokeColor: CONSTANT.COLOR_FIELD_STROKE});
            });
            vars.fieldShapes[fieldId].polygon.setOptions({strokeColor: CONSTANT.COLOR_ACTIVE_FIELD_STROKE});
        }

        function projectPointOnLine(l1, l2, p) {
            var overlayProjection = vars.gmap.getProjection();

            var x1 = overlayProjection.fromLatLngToPoint(l1).x;
            var y1 = overlayProjection.fromLatLngToPoint(l1).y;
            var x2 = overlayProjection.fromLatLngToPoint(l2).x;
            var y2 = overlayProjection.fromLatLngToPoint(l2).y;

            var prP = overlayProjection.fromLatLngToPoint(p);
            var x3 = prP.x;
            var y3 = prP.y;

            if (Math.abs(x2-x1) > CONSTANT.EPS && Math.abs(y2-y1) > CONSTANT.EPS) {
                var k1 = (y2 - y1) / (x2 - x1);
                var b1 =  y1 - x1 * k1;
                var k2 = -1/k1;
                var b2 = y3 - k2 * x3;
                var x4 = (b2-b1)/(k1-k2);
                var y4 = k1 * x4 + b1;
                return overlayProjection.fromPointToLatLng(new google.maps.Point(x4, y4));
            } else if (Math.abs(x2-x1) > CONSTANT.EPS) {
                return overlayProjection.fromPointToLatLng(new google.maps.Point(x3, y1));
            } else {
                return overlayProjection.fromPointToLatLng(new google.maps.Point(x1, y3));
            }
        }

        return {
            getP1: function (r, c, gridInfo) {
                var p1 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(gridInfo.lat0, gridInfo.lon0), gridInfo.dy * r, gridInfo.angle + 90);
                p1 = google.maps.geometry.spherical.computeOffset(p1, gridInfo.dx * c, gridInfo.angle);
                return p1;
            },
            getP2: function(r, c, gridInfo) {
                var p2 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(gridInfo.lat0, gridInfo.lon0), gridInfo.dy * r, gridInfo.angle + 90);
                p2 = google.maps.geometry.spherical.computeOffset(p2, gridInfo.dx * (c + 1), gridInfo.angle);
                return p2;
            },
            getP3: function(r, c, gridInfo) {
                var p3 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(gridInfo.lat0, gridInfo.lon0), gridInfo.dy * (r + 1), gridInfo.angle + 90);
                p3 = google.maps.geometry.spherical.computeOffset(p3, gridInfo.dx * (c + 1), gridInfo.angle);
                return p3;
            },
            getP4: function(r, c, gridInfo) {
                var p4 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(gridInfo.lat0, gridInfo.lon0), gridInfo.dy * (r + 1), gridInfo.angle + 90);
                p4 = google.maps.geometry.spherical.computeOffset(p4, gridInfo.dx * c, gridInfo.angle);
                return p4;
            },
            init: function (mapPosition) {

                vars.fieldShapes = {};

                SELECTED_MARKER = { path: google.maps.SymbolPath.CIRCLE,
                    scale: 4,
                    strokeColor: CONSTANT.COLOR_SELECTED_FIELD_STROKE,
                    fillColor: CONSTANT.COLOR_SELECTED_FIELD_STROKE,
                    fillOpacity: 1};

                UNSELECTED_MARKER = { path: google.maps.SymbolPath.CIRCLE,
                    scale: 4,
                    strokeColor: CONSTANT.COLOR_FIELD_STROKE,
                    fillColor: CONSTANT.COLOR_FIELD_STROKE,
                    fillOpacity: 1};

                var styleArray = [
                    {
                        featureType: "poi",
                        stylers: [
                            { visibility: "off" }
                        ]
                    },
                    {
                        featureType: "transit",
                        stylers: [
                            { visibility: "off" }
                        ]
                    }
                ];

                var zoom = defaultZoom;
                var center = defaultCenter;
                if (!_.isUndefined(mapPosition.zoom) && !_.isUndefined(mapPosition.lat) && !_.isUndefined(mapPosition.lng)) {
                    zoom = parseInt(mapPosition.zoom);
                    center = new google.maps.LatLng(parseFloat(mapPosition.lat), parseFloat(mapPosition.lng));
                }

                vars.gmap = new google.maps.Map(document.getElementById('gmap'), {
                    center: center,
                    zoom: zoom,
                    mapTypeId: google.maps.MapTypeId.HYBRID,
                    zoomControl: true,
                    fullscreenControl: false,
                    zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.SMALL,
                        position: google.maps.ControlPosition.RIGHT_BOTTOM
                    },
                    disableDoubleClickZoom: false,
                    streetViewControl: false,
                    panControl: false,
                    mapTypeControl: false,
                    styles: styleArray,
                    tilt: 0,
                    gestureHandling: 'greedy'
                });
            },
            enableUrlChangeOnMapMove: function() {
                vars.gmap.addListener("idle", function () {
                    var center = vars.gmap.getCenter();
                    $rootScope.$safeApply(function() {
                        $rootScope.$broadcast('mapService:idle');
                        _v.change({set: {lat: center.lat(), lng: center.lng(), zoom: vars.gmap.getZoom()}});
                    });
                });
                $rootScope.$broadcast('mapService:idle');
            },
            getBounds: function() {
                var bounds = vars.gmap.getBounds();
                if (bounds) {
                    return [bounds.getSouthWest().lng(), bounds.getSouthWest().lat(), bounds.getNorthEast().lng(), bounds.getNorthEast().lat()];
                } else {
                    return null;
                }
            },
            disableUrlChangeOnMapMove: function() {
                if (vars.gmap) {
                    google.maps.event.clearListeners(vars.gmap, 'idle');
                }
            },
            drawField: function(field) {
                //show or draw required field
                if (_.has(vars.fieldShapes, field.id)) {
                    //do nothing
                } else {
                    var shape = {};
                    if (field.newField) { //field was created in edit mode
                        if (field.shape == CONSTANT.POLYGON) {
                            shape.polygon = field.object;
                            shape.shape = CONSTANT.POLYGON;
                        }
                        if (field.shape == CONSTANT.CIRCLE) {
                            shape = field.object; //gCircle
                            shape.shape = CONSTANT.CIRCLE;
                        }
                    } else { //field was received by API
                        if (field.shape == CONSTANT.POLYGON) {
                            shape = { polygon: geojsonToPolygon(field.coordinates), shape: CONSTANT.POLYGON };
                            shape.polygon.setOptions(CONSTANT.fieldPolyOptions);
                            shape.polygon.setMap(vars.gmap);
                        }
                        if (field.shape == CONSTANT.CIRCLE) {
                            shape = geojsonToGCircle(field); //gCircle
                            shape.shape = CONSTANT.CIRCLE;
                        }
                    }

                    vars.fieldShapes[field.id] = shape;
                    shape.fieldId = field.id;
                    shape.fieldName = field.name;
                    shape.polygon.fieldId = field.id;
                }
            },
            decorateField: decorateField,
            colorFields: colorFields,
            colorActiveField: colorActiveField,
            panToFields: function(fields) {
                if (_.size(fields) < 1) {
                    return ;
                }
                var bounds;
                var path;
                var arr = [];
                fields.forEach(field => {
                    path = vars.fieldShapes[field.id].polygon.getPath();
                    for (var i = path.getLength(); i--;) {
                        var point = path.getAt(i);
                        arr.push([point.lat(), point.lng()]);
                    }
                });
                bounds = getLatLngBounds(arr);
                vars.gmap.fitBounds(bounds);
            },
            resize: function () {
                if (vars.gmap) {
                    google.maps.event.trigger(vars.gmap, 'resize');
                }
            },
            clear: function () {
                _.values(vars.fieldShapes).forEach(fieldShape => {
                    if (fieldShape.shape == CONSTANT.POLYGON) {
                        fieldShape.polygon.setEditable(false);
                        fieldShape.polygon.setMap(null);
                    }
                    if (fieldShape.shape == CONSTANT.CIRCLE) {
                        fieldShape.setEditable(false);
                        fieldShape.setMap(null);
                    }
                    fieldShape.overlay.setMap(null);
                });


                vars.fieldShapes = {};
            },

            circleToGCircle: circleToGCircle,
            projectPointOnLine: projectPointOnLine,
            switchWMSOverlay: function (on) {
                if (on) {
                    //Define custom WMS tiled layer
                    var SLPLayer = new google.maps.ImageMapType({
                        getTileUrl: function (coord, zoom) {
                            var proj = vars.gmap.getProjection();
                            var zfactor = Math.pow(2, zoom);
                            // get Long Lat coordinates
                            var top = proj.fromPointToLatLng(new google.maps.Point(coord.x * 256 / zfactor, coord.y * 256 / zfactor));
                            var bot = proj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * 256 / zfactor, (coord.y + 1) * 256 / zfactor));

                            //create the Bounding box string
                            var bbox = top.lng() + "," + bot.lat() + "," + bot.lng() + "," + top.lat();

                            //base WMS URL
                            var url = "https://geo.fertyle.com/geoserver/wbahia/gwc/service/wms?service=WMS";
                            url += "&REQUEST=GetMap"; //WMS operation
                            url += "&LAYERS=" + "western_bahia_02_10_2017_3857"; //WMS layers
                            url += "&FORMAT=image/png" ; //WMS format
                            url += "&TRANSPARENT=TRUE";
                            url += "&BBOX=" + bbox;      // set bounding box
                            url += "&WIDTH=256";         //tile size in google
                            url += "&HEIGHT=256";
                            //console.log(url);
                            return url;                 // return URL for the tile

                        },
                        tileSize: new google.maps.Size(256, 256),
                        isPng: true
                    });
                    vars.gmap.overlayMapTypes.push(SLPLayer);
                } else {
                    vars.gmap.overlayMapTypes.clear();
                }
            },
            vars: vars,
            CONSTANT: CONSTANT
        };
    });
